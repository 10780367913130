import _isGlob from "is-glob";
import _globBase from "glob-base";
import _isExtglob from "is-extglob";
import _isDotfile from "is-dotfile";
var exports = {};
var isGlob = _isGlob;
var findBase = _globBase;
var extglob = _isExtglob;
var dotfile = _isDotfile;
/**
 * Expose `cache`
 */

var cache = exports.cache = {};
/**
 * Parse a glob pattern into tokens.
 *
 * When no paths or '**' are in the glob, we use a
 * different strategy for parsing the filename, since
 * file names can contain braces and other difficult
 * patterns. such as:
 *
 *  - `*.{a,b}`
 *  - `(**|*.js)`
 */

exports = function parseGlob(glob) {
  if (cache.hasOwnProperty(glob)) {
    return cache[glob];
  }

  var tok = {};
  tok.orig = glob;
  tok.is = {}; // unescape dots and slashes in braces/brackets

  glob = escape(glob);
  var parsed = findBase(glob);
  tok.is.glob = parsed.isGlob;
  tok.glob = parsed.glob;
  tok.base = parsed.base;
  var segs = /([^\/]*)$/.exec(glob);
  tok.path = {};
  tok.path.dirname = "";
  tok.path.basename = segs[1] || "";
  tok.path.dirname = glob.split(tok.path.basename).join("") || "";
  var basename = (tok.path.basename || "").split(".") || "";
  tok.path.filename = basename[0] || "";
  tok.path.extname = basename.slice(1).join(".") || "";
  tok.path.ext = "";

  if (isGlob(tok.path.dirname) && !tok.path.basename) {
    if (!/\/$/.test(tok.glob)) {
      tok.path.basename = tok.glob;
    }

    tok.path.dirname = tok.base;
  }

  if (glob.indexOf("/") === -1 && !tok.is.globstar) {
    tok.path.dirname = "";
    tok.path.basename = tok.orig;
  }

  var dot = tok.path.basename.indexOf(".");

  if (dot !== -1) {
    tok.path.filename = tok.path.basename.slice(0, dot);
    tok.path.extname = tok.path.basename.slice(dot);
  }

  if (tok.path.extname.charAt(0) === ".") {
    var exts = tok.path.extname.split(".");
    tok.path.ext = exts[exts.length - 1];
  } // unescape dots and slashes in braces/brackets


  tok.glob = unescape(tok.glob);
  tok.path.dirname = unescape(tok.path.dirname);
  tok.path.basename = unescape(tok.path.basename);
  tok.path.filename = unescape(tok.path.filename);
  tok.path.extname = unescape(tok.path.extname); // Booleans

  var is = glob && tok.is.glob;
  tok.is.negated = glob && glob.charAt(0) === "!";
  tok.is.extglob = glob && extglob(glob);
  tok.is.braces = has(is, glob, "{");
  tok.is.brackets = has(is, glob, "[:");
  tok.is.globstar = has(is, glob, "**");
  tok.is.dotfile = dotfile(tok.path.basename) || dotfile(tok.path.filename);
  tok.is.dotdir = dotdir(tok.path.dirname);
  return cache[glob] = tok;
};
/**
 * Returns true if the glob matches dot-directories.
 *
 * @param  {Object} `tok` The tokens object
 * @param  {Object} `path` The path object
 * @return {Object}
 */


function dotdir(base) {
  if (base.indexOf("/.") !== -1) {
    return true;
  }

  if (base.charAt(0) === "." && base.charAt(1) !== "/") {
    return true;
  }

  return false;
}
/**
 * Returns true if the pattern has the given `ch`aracter(s)
 *
 * @param  {Object} `glob` The glob pattern.
 * @param  {Object} `ch` The character to test for
 * @return {Object}
 */


function has(is, glob, ch) {
  return is && glob.indexOf(ch) !== -1;
}
/**
 * Escape/unescape utils
 */


function escape(str) {
  var re = /\{([^{}]*?)}|\(([^()]*?)\)|\[([^\[\]]*?)\]/g;
  return str.replace(re, function (outter, braces, parens, brackets) {
    var inner = braces || parens || brackets;

    if (!inner) {
      return outter;
    }

    return outter.split(inner).join(esc(inner));
  });
}

function esc(str) {
  str = str.split("/").join("__SLASH__");
  str = str.split(".").join("__DOT__");
  return str;
}

function unescape(str) {
  str = str.split("__SLASH__").join("/");
  str = str.split("__DOT__").join(".");
  return str;
}

export default exports;
const _cache = exports.cache;
export { _cache as cache };